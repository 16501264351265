




















import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class JobCalendarLegendComponent extends Vue {

  public isOpen: boolean = false;

  private get jobStatus(): string[] {
    return ['done', 'in-progress', 'not-touched', 'in-future', 'not-planned'];
  }

  public toggleState() {
    this.isOpen = !this.isOpen;
  }

  private formatForLocales(text: string) {
    return text.replace('-', '_').toUpperCase();
  }
}
